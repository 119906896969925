import React from 'react';
import Navbar from '../components/Navbar';
import styles from "./withSidebar.module.scss";

const ContentLayout = ({ children }) => (
  <div className={styles.page}>
    <Navbar />
    <div className={styles.container}>{children}</div>
  </div>
)

export default ContentLayout;